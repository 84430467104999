// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.portal.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppEnvironment } from '../app/app-environment';
import packageJson from '../../package.json'
export const environment: AppEnvironment = {
  production: false,
  adminApiBaseUrl: 'https://api-admin-bsi-dev-003.internal.bsiconnect.app/api',
  authApiBaseUrl: 'https://api-auth-bsi-dev-003.internal.bsiconnect.app/api',
  documentApiBaseUrl: 'https://api-document-bsi-dev-003.internal.bsiconnect.app/api',
  storageApiBaseUrl: 'https://api-storage-bsi-dev-003.internal.bsiconnect.app/api',
  clientId: 'df6dc385-c2ad-4b45-8fe3-1bfb6369b483',
  authority: 'https://bsiconnectauthdev.b2clogin.com/bsiconnectauthdev.onmicrosoft.com/B2C_1_SUSI',
  knownAuthorities: ['https://bsiconnectauthdev.b2clogin.com'],
  redirectUrl: 'https://portal-dev-003.internal.bsiconnect.app',
  authApiScope: 'https://bsiconnectauthdev.onmicrosoft.com/auth-api/all',
  buildId: '143628',
  buildNumber: '20240913.23',
  pipeline: 'artemis-core-internal',
  branch: 'refs/heads/bug/null-values-are-shown-after-reset-refresh',
  stage: 'internal',
  repository: 'artemis-core',
  commit: '7d3af366569b1b667bbed8251a3acbdf3ce5fa22',
  environmentId: '109',
  environmentName: 'bsi-connect-platform-internal-dev',
  version: packageJson.version,
  workspaceUrl: 'https://dev-003.internal.bsiconnect.app/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
